<template>
  <div ref="richTextEditor">
    <FloatingMenu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
      <div class="floating-menu">
        <TnButton
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            size="xs"
            :secondary="editor.isActive('heading', { level: 1 })"
            tertiary>
          H1
        </TnButton>
        <TnButton
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            size="xs"
            :secondary="editor.isActive('heading', { level: 2 })"
            tertiary>
          H2
        </TnButton>
        <TnButton
            @click="editor.chain().focus().toggleBulletList().run()"
            size="xs"
            :secondary="editor.isActive('bulletList')"
            title="Bullet list"
            tertiary>
          <TnIcon name="view-list" size="s"></TnIcon>
        </TnButton>
        <TnButton
            @click="setImage"
            size="xs"
            :secondary="editor.isActive('image')"
            title="Image"
            tertiary>
          <TnIcon name="camera" size="s"></TnIcon>
        </TnButton>
      </div>
    </FloatingMenu>
    <EditorContent
        :editor="editor"
        class="editable-property"
    />
    <BubbleMenu
        :editor="editor"
        :tippy-options="{ duration: 100, appendTo: $refs.tippycontainer }"
        v-if="editor"
    >
      <div class="bubble-menu">
        <TnButton
            @click="editor.chain().focus().toggleBold().run()"
            size="xs"
            :secondary="editor.isActive('bold')"
            tertiary
        >
          Bold
        </TnButton>
        <TnButton
            @click="editor.chain().focus().toggleItalic().run()"
            size="xs"
            :secondary="editor.isActive('italic')"
            tertiary
        >
          Italic
        </TnButton>
        <TnButton
            @click="editor.chain().focus().toggleHeading({level: 1}).run()"
            size="xs"
            :secondary="editor.isActive('heading', {level: 1})"
            tertiary
        >
          H1
        </TnButton>
        <TnButton
            @click="editor.chain().focus().toggleHeading({level: 2}).run()"
            size="xs"
            :secondary="editor.isActive('heading', {level: 2})"
            tertiary
        >
          H2
        </TnButton>
        <TnButton
            @click="setLink"
            size="xs"
            :secondary="editor.isActive('strike')"
            tertiary
        >
          <TnIcon
              name="link"
              size="s"
              class="margin-right-s"
          />
          Link
        </TnButton>
      </div>
    </BubbleMenu>
    <div ref="tippycontainer"></div>
  </div>
</template>

<script setup>
import {useEditor, EditorContent, BubbleMenu, FloatingMenu} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import {Link} from "@tiptap/extension-link";
import {Image} from "@tiptap/extension-image";

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  path: {
    type: String,
    default: "",
  }
});

const content = ref(props.text);

const el = useTemplateRef('richTextEditor');

const editor = useEditor({
  content: content.value,
  editable: true,
  onBlur({editor}) {
    updateState(editor)
  },
  extensions: [
    StarterKit,
    Link.configure({
      openOnClick: false,
      defaultProtocol: "https",
    }),
    Image
  ],
});

const updateState = (editor) => {
  el.value.dispatchEvent(new CustomEvent("contentChanged", {
    detail: {
      prop: props.path,
      oldValue: props.text,
      unknownProp: !props.path,
      value: editor.getHTML(),
    },
    bubbles: true,
    composed: true,
  }));
};

const setLink = () => {
  useGizmoStore().getLinkCallback = result => {

    if (result.value)
      result = result.value;

    const url = result.href;

    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor
          .value
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

      return
    }

    // update link
    editor
        .value
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink(result)
        .run();

    updateState(editor.value);
  }

  const previousUrl = editor.value.getAttributes('link').href

  parent.postMessage(
      {
        action: "getLink",
        payload: {
          value: {
            href: previousUrl
          },
        },
      },
      "*",
  );
};

const setImage = () => {
  useGizmoStore().getImageCallback = result => {
    if (result.value)
      result = result.value;

    const src = result.src;

    if (src === null) {
      return
    }

    // insert image
    editor
        .value
        .chain()
        .focus()
        .setImage(result)
        .run();

    updateState(editor.value);
  }

  parent.postMessage(
      {
        action: "getImage",
        payload: {
          value: {},
        },
      },
      "*",
  );
};

onBeforeUnmount(() => {
  editor.value.destroy();
});
</script>

<style lang="scss" scoped>
/* Bubble menu */

.bubble-menu, .floating-menu {
  display: flex;

  :deep(.button) {
    margin-bottom: 0 !important;
    margin-left: $spacing-xs;

    &:first-child {
      margin-left: 0 !important;
    }
  }

  :deep(.text) {
    display: flex;
    align-items: center;
  }
}

.floating-menu {
  position: relative;
  left: -10px;
}

.bubble-menu {
  border-radius: 50px;
  background: #fff;
  backdrop-filter: blur(10px);
  @include shadow(s);
}
</style>
